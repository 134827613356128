import React from 'react';
import {
  PageHero,
  GoodBad,
  GoodBadItem,
  Link,
  List,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../../data/pages/words.yml';

const IndexPage = () => {
  return (
    <PageWithSubNav pageType="design" title="Punctuation" subnav="words">
      <PageHero
        heroData={pageHeroData}
        tierTwo="Writing Guidelines"
        tierThree="Punctuation"
      />

      <Section>
        <Paragraph>
          Because punctuation can make or break whatever it is youre trying to
          communicate and essentially change the tone but how do you know what
          to use where and when is a piece of punctuation right or wrong is
          there even a right and wrong because sometimes in writing theres only
          good and bad not right and wrong but if youre still reading this
          paragraph youre probably very aware of just how bad a lack of
          punctuation can be
        </Paragraph>

        <Paragraph>
          Then. there’s the issue of - using punctuation, where it just doesn’t
          belong? And that can sometimes & have a worse effect, on the writing
          because you’re: interrupting the. reader’s flow and essentially
          making! the whole thing even harder to read like harder: than if there
          was? no punctuation - at all.
        </Paragraph>

        <Paragraph>
          So we put together guidelines covering the most common pieces to help
          you determine what goes where.
        </Paragraph>
      </Section>
      <Section>
        <SectionSubhead>Periods</SectionSubhead>
        <Paragraph>
          The period gives your thought a hard stop before beginning a brand
          new, self-sustaining sentence. Think of it as an extended pause
          between the two.
        </Paragraph>

        <Paragraph>A period is a period—period.</Paragraph>

        <Paragraph>
          It should be used at the end of just about every sentence that’s not a
          question. It has every right to appear in body copy, captions,{' '}
          <Link href="/components/type/subhead/design">subheads</Link> and{' '}
          <Link href="/components/tooltip/design">tooltips</Link>.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              We help teams and athletes win. With the tools to review and
              improve performance.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              We help teams and athletes win with the tools to review and
              improve performance.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> put one between phrases that can and should
              live together.
            </li>
            <li>
              <strong>Do</strong> add a period to finalize the thought.
            </li>
          </List>
        </Paragraph>

        <Paragraph>
          Make sure the string preceding your period is a complete sentence.
          There should be a subject and a verb, maybe even an object.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>New this season.</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              Check our tutorials for a closer look at what’s new.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> put it on a fragment in an effort to
              emphasize.
            </li>
            <li>
              <strong>Do</strong> use periods to clearly separate complete
              sentences.
            </li>
          </List>
        </Paragraph>

        <Paragraph>
          If what you’re punctuating is actually a fragment, try editing or
          attaching it to something else to form the complete thought.
        </Paragraph>

        <Paragraph>
          As for abbreviations, anything in the product should not have a period
          at the end. This is especially important when abbreviating table
          headers, “versus” and months.
        </Paragraph>

        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              LHS vs. BHS on Sat., 26 Jan. has been postponed.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              LHS vs BHS is scheduled for Sun, 27 Jan at 4pm.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> clutter an already packed interface with
              periods.
            </li>
            <li>
              <strong>Do</strong> abbreviate with no period when space is at a
              minimum.
            </li>
          </List>
        </Paragraph>

        <Paragraph>
          For more on where periods often appear, check our{' '}
          <Link href="/words/content-elements/labels-non-label-text">
            non-label guidelines
          </Link>
          .
        </Paragraph>
      </Section>

      <Section>
        <SectionSubhead>Exclamation Points</SectionSubhead>
        <Paragraph>
          By definition, the exclamation point is used to indicate{' '}
          <strong>force or strong feeling</strong>. That’s why we use them{' '}
          <em>sparingly</em>
          —too many and we risk yelling at the user and/or feigning excitement.
          Neither is a good look.
        </Paragraph>
        <Paragraph>
          There are two clear-cut moments where the exclamation is a no-brainer:
        </Paragraph>
        <Paragraph>
          <List type="unordered">
            <li>
              What we’re telling them is really, truly, without a doubt going to
              change their life. Not in the marketing “this product is pretty
              awesome” sense, but in the “this could be our biggest release of
              all time” sense.
            </li>
            <li>
              The message preceding the exclamation is beyond important. They’re
              about to delete something forever, make an irreversible change or
              spend $1 million. Make sure they realize the significance.
            </li>
          </List>
        </Paragraph>
        <Paragraph>
          If you have to use an exclamation,{' '}
          <strong>use one and only one</strong>. Should you ever try putting
          multiple on the end of a single sentence, your keyboard will catch
          fire. Them’s the rules.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Best day ever! Your upload is complete. Visit your video page to
              see it in action.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              Invites sent! Your coaches and athletes will receive an email with
              additional instructions.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> tack it on just to seem cute and fun. Words
              can do that.
            </li>
            <li>
              <strong>Do</strong> use an exclamation point to convey the
              importance of a given message.
            </li>
          </List>
        </Paragraph>
        <Paragraph>
          You’re allowed one exclamation point for an entire workflow or
          interface. That’s not to say one is <em>required</em>, just that its
          time and place should be carefully considered.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Best day ever! Your upload is complete! Visit your video page to
              see it in action!
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              Invites sent. Don’t forget to add vital stats for all incoming
              freshmen!
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> put an exclamation on the end of every
              sentence.
            </li>
            <li>
              <strong>Do</strong> save the exclamation for the most exciting or
              important piece of content.
            </li>
          </List>
        </Paragraph>

        <Paragraph>
          If you’re still concerned about conveying the right tone,{' '}
          <Link href="/words/writing-guidelines/voice-tone">
            these guidelines
          </Link>{' '}
          might help.
        </Paragraph>
      </Section>

      <Section>
        <SectionSubhead>Question Marks</SectionSubhead>
        <Paragraph>Questions marks are for questions.</Paragraph>
        <Paragraph>
          If you put a question mark at the end of a sentence, make sure we’re
          really asking a question and the answer is easy—either as a button or
          input, or in the form of a link to “learn more.”
        </Paragraph>
        <Paragraph>
          Like exclamation points, they should be used sparingly, otherwise our
          whole product becomes one big Q&A. And that’s weird.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              We might’ve just added a new special teams playbook? Log in and
              see for yourself.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              Ready to share another playlist? Check your video page to see
              what’s available.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> add a question mark for the sake of
              inflection.
            </li>
            <li>
              <strong>Do</strong> use a question to give your content a natural
              flow.
            </li>
          </List>
        </Paragraph>
        <Paragraph>
          Questions work well in headlines and confirmations, where the product
          or user can provide an answer. Avoid putting them where interaction is
          harder to come by, like an empty state or tooltip.
        </Paragraph>{' '}
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Time and place are set. Video’s shared. Uniforms washed.
            </Paragraph>
            <Paragraph>But are you really ready for game day?</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>Delete Athlete?</Paragraph>
            <Paragraph>
              Deleting an athlete will also remove all highlights under their
              name.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> provide a question with no real answer.
            </li>
            <li>
              <strong>Do</strong> keep the question relevant to the current
              workflow.
            </li>
          </List>
        </Paragraph>
        <Paragraph>
          The main reason every answer should be obvious? A question they{' '}
          <em>can’t</em> answer could come off as condescending, and that’s not
          our style.
        </Paragraph>
      </Section>

      <Section>
        <SectionSubhead>Commas</SectionSubhead>
        <Paragraph>
          The comma indicates a pause in the sentence. If you read a piece of
          content and realize it rambled with very few opportunities to breathe,
          you’re probably missing a few commas.
        </Paragraph>
        <Paragraph>
          Commas are also used to separate items in a list, and this is where
          things get tricky. Hudl follows AP Style, therefore{' '}
          <strong>we do not use the Oxford comma</strong>. This means we don’t
          put a comma before the conjunction in a <em>simple</em> list.
        </Paragraph>
        <Paragraph>
          We do, however, add it to complex lists that wouldn’t make much sense{' '}
          <em>without</em> the comma.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Coaches and athletes can edit, study, and share film on Hudl.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              Coaches can upload full games and practices, exchange with their
              conference or a single opponent, and create highlights for the
              whole team.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> add it to just any list. The
              pause/separation of those items isn’t necessary.
            </li>
            <li>
              <strong>Do</strong> include it with longer items that could blur
              together.
            </li>
          </List>
        </Paragraph>

        <Paragraph>
          In any non-list situation, your best “test” in determining the
          necessity of a comma is to read the thing out loud. Anytime you pause
          for effect or the separation of two clauses, the comma is a safe bet.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              You can share any full game or custom playlist, with the entire
              team or individual athletes, just by selecting the intended
              recipients from a list of team members.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              When it comes to sharing custom playlists with coaches and
              athletes, all existing comments and drawings will be visible.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <List type="unordered">
          <li>
            <strong>Don’t</strong> add them just because the sentence looks long
            and breathless. Try trimming instead.
          </li>
          <li>
            <strong>Do</strong> rely on commas for natural pauses and increased
            clarity.
          </li>
        </List>
      </Section>

      <Section>
        <SectionSubhead>Apostrophes</SectionSubhead>
        <Paragraph>
          Perhaps the most important thing about apostrophes is what they{' '}
          <em>don’t</em> do.
        </Paragraph>
        <Paragraph>
          <strong>Apostrophes do not make singular words plural.</strong>
        </Paragraph>
        <Paragraph>
          “Saturday’s” are not for football. Saturdays, on the other hand, can
          be.
        </Paragraph>
        <Paragraph>Adding an apostrophe should do one of two things:</Paragraph>
        <Paragraph>
          <List type="unordered">
            <li>Indicate a contraction (it’s, we’re, he’ll, etc.)</li>
            <li>
              Indicate ownership (Michael’s, the president’s, our neighbors’)
            </li>
          </List>
        </Paragraph>
        <Paragraph>
          And for what it’s worth, we <em>love</em> contractions.
        </Paragraph>{' '}
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              You’d see a critical message if the upload had failed. It’d be
              bright red with a giant exclamation.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              Your order is being processed as we speak. We’ll email the
              download as soon as it’s ready.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> use the contraction if it actually sounds
              better without.
            </li>
            <li>
              <strong>Do</strong> make things more conversational with natural
              contractions.
            </li>
          </List>
        </Paragraph>
        <Paragraph>
          Ownership is where things get tricky. Most of our teams are named
          according to the participating gender (who the team "belongs" to). But
          the genders are plural—
          <em>boys</em> and <em>girls</em>
          —so where does the apostrophe go?
        </Paragraph>
        <Paragraph>
          When mentioning sports teams, the apostrophe will almost always go on
          the very end.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Boy’s varsity football highlights are ready to share! Log in to
              check them out.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              We just received your payment order for girls’ volleyball and
              boys’ soccer.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> assume the S alone keeps things plural.
            </li>
            <li>
              <strong>Do</strong> put the apostrophe on the end for a plural
              possessive.
            </li>
          </List>
        </Paragraph>
        <Paragraph>
          If you’re still not convinced, swap in men/women for a quick test:
        </Paragraph>
        <Paragraph>
          <List type="unordered">
            <li>boys’ soccer = men’s soccer</li>
            <li>girl’s volleyball = woman’s volleyball</li>
          </List>
        </Paragraph>
      </Section>

      <Section>
        <SectionSubhead>Dashes</SectionSubhead>
        <Paragraph>
          There are three (yes, three) different dashes available to all via
          keyboard. The hyphen -, the en dash – and the em dash —. Contrary to
          popular belief, <strong>they are not interchangeable</strong>.
        </Paragraph>
        <SectionSubhead>Hyphens</SectionSubhead>
        <Paragraph>
          The fun thing about a hyphen? It can be used in the middle of numbers
          or letters. In the case of numbers, use hyphens to separate
          non-inclusive digits, like a phone number or Social Security number.
          With letters, you can use a hyphen to make one word out of two, like{' '}
          <em>high-quality</em> or <em>decision-maker</em>.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Your video is ready to view-click below to watch it now.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              We're bringing first-class support to the Hudl app.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> use the hyphen to offset separate phrases.
            </li>
            <li>
              <strong>Do</strong> use the hyphen to indicate words or letters
              belong together.
            </li>
          </List>
        </Paragraph>
        <Paragraph>
          Regardless of which need it serves, never surround the hyphen with
          spaces. Mash it all together for the ultimate hyphen experience.
        </Paragraph>
        <SectionSubhead>En Dashes</SectionSubhead>
        <Paragraph>
          The en dash is for numbers, specifically when presenting a range. Nine
          times out of 10, it will sit there to signify <em>through</em>. The
          other 10% is reserved for scores.
        </Paragraph>
        <Paragraph>
          And when we say <em>through</em>, we mean everything from page numbers
          to date and time.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>You can call us anytime at 402–817–0060.</Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              Plan on being at the arena next Saturday, 5–7pm.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> use the en dash to simply join two separate
              numbers.
            </li>
            <li>
              <strong>Do</strong> use an en dash to “fill” a range.
            </li>
          </List>
        </Paragraph>
        <Paragraph>
          The en dash is an exception to how we use space with dashes. Be sure
          to <strong>add a space on both sides</strong> to make the full range
          more legible.
        </Paragraph>
        <SectionSubhead>Em Dashes</SectionSubhead>
        <Paragraph>
          The longest of the three—the em dash—is also the most versatile. It
          can be used in place of commas, parentheses, even a semicolon! Anytime
          you’d offset a phrase, whether in the middle of a sentence or as an
          afterthought at the end, an em dash will do the trick.
        </Paragraph>
        <Paragraph>
          <em>
            Note that the em dash should only be used in longer blocks of text.
            Applications within the product are likely onboarding content and
            other wordy modals.
          </em>
        </Paragraph>
        <Paragraph>
          It’s easy to confuse the en dash and em dash. Consider any instance of
          the em dash a pause—it wouldn’t make much sense with ranges. And it{' '}
          <em>definitely</em> wouldn’t make sense when trying to tidy one little
          string, so leave your hyphen alone.
        </Paragraph>
        <Paragraph>
          That said, the em dash does share one thing with the hyphen: It should
          never have spaces on either end. It takes up enough room as it is.
        </Paragraph>
        <GoodBad gridLayout="2">
          <GoodBadItem type="bad">
            <Paragraph>
              Your game has been submitted. Our analysts will return the video
              and its data in 12—24 hours.
            </Paragraph>
          </GoodBadItem>
          <GoodBadItem type="good">
            <Paragraph>
              If you have any questions about the Assist process, don't
              hesistate to get in touch—we'll be here all weekend.
            </Paragraph>
          </GoodBadItem>
        </GoodBad>
        <Paragraph>
          <List type="unordered">
            <li>
              <strong>Don’t</strong> use the em dash in any string of numbers.
            </li>
            <li>
              <strong>Do</strong> use the em dash to clearly separate trains of
              thought.
            </li>
          </List>
        </Paragraph>
      </Section>

      <Section>
        <SectionSubhead>Dividers</SectionSubhead>
        <Paragraph>
          <Link href="/guidelines/space/design">Our space guidelines</Link>{' '}
          should help in clearly dividing content in a shared space—even those
          instances where many items live on a single line.
        </Paragraph>
        <Paragraph>
          You’re encouraged to use space every time. In the case that won’t get
          the job done,{' '}
          <strong>please don’t add a vertical bar or dash just because</strong>.
          (And definitely don’t use a • !){' '}
          <Link href="https://docs.google.com/forms/d/e/1FAIpQLSfm6PwUOsdBBtFGlzHnqVjzH85-rHAzHNhx0u4bgTiy0lukJg/viewform">
            Reach out to Uniform
          </Link>{' '}
          and we’ll work together to find an alternative.
        </Paragraph>
      </Section>
    </PageWithSubNav>
  );
};

export default IndexPage;
